<template>
  <div class="boxDia">
    <el-row class="dash-board">
      <el-col :span="24">
        <div class="a" style="font-size: 12px">班级:</div>
        <div class="b" style="font-size: 12px">姓名:</div>
        <div class="c" style="font-size: 12px">状态:</div>
        <!-- <el-input style="width: 190px" class="d" v-model="className"></el-input> -->
         <el-select style="width: 190px" class="d" placeholder="请选择班级" v-model="className"  filterable clearable>
            <el-option v-for="(item,index) in classData"
                      :key="index"
                      :label="item.className"
                      :value="item.classId"/>
        </el-select>
        <el-select class="e" v-model="status" clearable >
          <el-option v-for="(i,k) in dictionaryList5" :key="k" :label="i.value" :value="i.sort">
          </el-option>
        </el-select>
        <el-input  placeholder="输入姓名" style="width: 120px; padding: 0 20px"  class="f" v-model="student_Name"></el-input>
        <div class="select-btn-box" style="position: absolute;">
          <el-button type="primary" v-permission="['Diagram:select']" class="" @click="searchChange()">查询</el-button>
          <el-button type="warning" class="" @click="reset">重置</el-button>
          <el-button type="primary" v-permission="['Diagram:batch']" class="" @click="mach">批量审批</el-button>
        </div>
      </el-col>
      <el-dialog :title="titlee" :visible.sync="macher">
        <el-button @click="auther(2)">通过</el-button>
        <el-button @click="machers = true">不通过</el-button>
      </el-dialog>
      <el-dialog :title="titleee" :visible.sync="machers">
        <el-input type="textarea" rows="5" style="width: 500px" v-model="remark"></el-input>
        <el-button @click="machers = false">取消</el-button>
        <el-button @click="auther(3)">确定</el-button>
      </el-dialog>
    </el-row>
    <el-row class="bg">
      <el-table
        :data="tableData"
        style="width: 100%;"
        @selection-change="handleSelectionChange"
        ref="multipleTable"
        tooltip-effect="dark"
        class="tables"
      >
        <el-table-column
          type="selection"
        >
        </el-table-column>
        <el-table-column
          label="序号"
          align="center"
          width="100" >
          <template slot-scope="tableData">
            <span>{{ tableData.row.stuId }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="姓名"
          align="center"
          width="120">
          <template slot-scope="tableData">
            <span>{{ tableData.row.stuName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="单位名称"
          align="center"
          width="130">
          <template slot-scope="tableData">
            <span>{{ tableData.row.departmentName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="职务"
          align="center"
          width="200">
          <template slot-scope="tableData">
            <span>{{ tableData.row.stuDuty }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="班级"
          align="center">
          <template slot-scope="tableData">
            <span>{{ tableData.row.className }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="联系电话"
          align="center"
          width="130">
          <template slot-scope="tableData">
            <span>{{ tableData.row.stuPhone }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="状态"
          align="center"
          width="100">
          <template slot-scope="tableData">
            <el-tag type="danger" v-if="tableData.row.status==1">待审核</el-tag>
            <el-tag v-if="tableData.row.status==2">审核通过</el-tag>
            <el-tag type="warning" v-if="tableData.row.status==3">审核不通过</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="260px">
          <template slot-scope="scope">
            <el-button size="mini" v-permission="['Diagram:approval']" @click="open(scope.row)" type="primary" :disabled="!(scope.row.status === 1)">审批</el-button>
            <el-button size="mini" @click="Delete(scope.row)" type="danger">删除</el-button>
            <el-button size="mini" @click="ChangeClass(scope.row)" v-if="scope.row.status==1" type="primary">转班</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-dialog :title="titlee" :visible.sync="handleEdit">
      <el-button @click="auth()">通过</el-button>
      <el-button @click="transit = true">不通过</el-button>
    </el-dialog>
    <el-dialog :title="titleee" :visible.sync="transit">
      <el-input type="textarea" rows="5" style="width: 500px" v-model="remark"></el-input>
      <el-button @click="transit = false">取消</el-button>
      <el-button @click="noAuth()">确定</el-button>
    </el-dialog>
    <!-- 转班 -->
    <el-dialog title="换班" :visible.sync="Classshow">
      <el-form>
        <el-form-item label="班级" label-width="150px">
          <el-select v-model="newClassId" placeholder="请选择班级" style="width:100%">
           <el-option v-for="(item,index) in classData"
                    :key="index"
                    :label="item.className"
                    :value="item.classId"/>
          </el-select>
        </el-form-item>
         <el-button @click="ClassCancel">取消</el-button>
         <el-button @click="newClassBt()" type="primary" :disabled="!newClassId">确定</el-button>
      </el-form>
    </el-dialog>
    <el-row style="margin-top:20px">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-size="pageSize"
      layout="total,sizes,  prev, pager, next, jumper"
      :total="total"
      class="pag"
    >
    </el-pagination>
    </el-row>
  </div>
</template>

<script>
  // import { getCookie } from '@/utils/base'
  import { getAllClassByMoHu, GetAllById } from '@/api/user.js' // 导入方法
  import { auth, noAuth, getApply, plAEStatus, DeleteApprove, newClass } from '@/api/admin.js' // 导入方法
  // import { getClassList } from '@/api/classApi'
  export default {
    name: 'Diagram',
    data () {
      return {
        Classshow: false,
        newClassId: '',
        classData: [], // 存储班级信息
        machers: false,
        macher: false,
        className: '',
        submitDisabled: false,
        transit: false,
        titleee: '审批语',
        titlee: '审批',
        handleEdit: false,
        dictionaryList5: '',
        dapartmentId: '', // 部门id
        student_Name: '', // 名字搜索
        stuId: '', // 学生id
        status: 1, // 状态
        classId: '',
        remark: '',
        tableData: [
          {
            stuId: '',
            stuName: '',
            stuBirth: '',
            sex: '',
            departmentName: '',
            stuPhone: '',
            status: '',
            stuDuty: ''
          }
        ],
        dataList: [], // 存放数据类型
        form: {},
        multipleSelection: [],
        total: 0,
        pageNum: 1,
        pageSize: 10,
        input: '',
        title: '添加商品',
        dialogFormVisible: false,
        formLabelWidth: '100px'
      }
    },
    methods: {
      // 获取班级列表
      findClass() {
        getAllClassByMoHu().then(response => {
          this.classData = response.data.data.rows
        })
      },
      handleSizeChange(e) {
        this.pageSize = e
        this.getApply()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.getApply()
      },
      getApply () { // 获取报名信息
        if (this.tableData.departmentName === '北海部') {
          this.dapartmentId = 170
        } else if (this.tableData.departmentName === '组织部') {
          this.dapartmentId = 160
        }
        const query = {
          dapartmentId: this.dapartmentId,
          stuName: this.student_Name,
          status: this.status,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          classId: this.className
        }
        getApply(query).then(resp => {
          if (resp.data.code === '0') {
            this.total = resp.data.data.total // 查询总条数
            this.tableData = resp.data.data.rows // 接收返回的数据
          }
        })
      },
      searchChange (e) {
        this.pageNum = 1
        this.pageSize = 10
        this.getApply()
      },
      reset() {
        this.pageNum = 1
        this.className = ''
        this.student_Name = ''
        this.status = ''
      },
      // eslint-disable-next-line vue/no-dupe-keys
      open (row) {
        this.handleEdit = true
        this.stuId = row.stuId
        this.classId = row.classId
      },
      GetAllById (id) { // 字典
        GetAllById(id).then(resp => {
          if (resp.data.code === '0') {
                this.dictionaryList5 = resp.data.data // 审批状态
                console.log(this.dictionaryList5)
            }
        })
      },
      mach() {
        this.macher = true
      },
      auth () { // 通过
        // this.stuId = id
        this.status = 2
        auth(this.stuId, this.classId, this.status).then(resp => {
          if (resp.data.code === '0') {
            this.submitDisabled = true
            this.handleEdit = false
            this.$notify({
              title: '审批成功',
              type: 'success'
            })
            this.getApply('', '', 0)
          }
        })
      },
      auther (status) { // 批量审批通过
        this.multipleSelection.forEach(item => {
          item.status = status
          if (this.remark !== '') {
            item.remark = this.remark
          }
        })
        plAEStatus(this.multipleSelection).then(resp => {
          if (resp.data.code === '0') {
            this.macher = false
            this.$notify({
              title: '审批成功',
              type: 'success'
            })
            this.remark = ''
            this.getApply('', '', 0)
          }
        })
      },
      noAuth () { // 审核不通过
        this.status = 3
        noAuth(this.stuId, this.classId, this.status, this.remark).then(resp => {
          if (resp.data.code === '0') {
            this.transit = false
            this.handleEdit = false
            this.$notify({
              title: '审批成功',
              type: 'success'
            })
            this.remark = ''
            this.getApply('', '', 0)
          }
        })
      },
      handleSelectionChange(respes) {
        console.log(respes)
        this.multipleSelection = respes
      },
      // Delete
      Delete(row) {
        this.$confirm('是否删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then((e) => {
          if (e === 'confirm') {
            DeleteApprove(row.stuId, row.classId).then(res => {
              if (res.data.code === '200') {
                this.$notify({
                  title: '删除成功',
                  type: 'success'
                })
                const index = this.tableData.indexOf(row)
                this.tableData.splice(index, 1)
                this.total--
              } else {
                this.$notify.error({
                  title: res.data.msg
                })
              }
            })
          }
        }).catch(() => {
        })
      },
      // 转班级
      ChangeClass(row) {
        this.Classshow = true
        this.stuId = row.stuId
        this.classId = row.classId
      },
      // 转班级提交
      newClassBt() {
        console.log(this.stuId, this.classId, this.newClassId)
        newClass(this.stuId, this.classId, this.newClassId).then(res => {
              if (res.data.code === '0') {
                this.$notify({
                  title: '转班成功',
                  type: 'success'
                })
                this.newClassId = ''
                this.Classshow = false
                this.getApply()
              } else {
                this.$notify.error({
                  title: res.data.msg
                })
              }
        })
      },
      // 转班级取消
      ClassCancel() {
        this.newClassId = ''
        this.Classshow = false
      }
    },
    mounted () {
      this.submitDisabled = false
      this.getApply('', '', 0) // 数据初始化加载
      this.GetAllById(180)
      this.findClass()
    }
  }
</script>

<style scoped>
  .boxDia{margin-top: -10px}
.dash-board {display: flex; background-color: white; height: 60px; padding: 10px 0;}
.bg{margin-top: 15px; padding: 20px; background-color: white;}
  .fom{
    display: flex;
    top: 33px;
    left: 50px;
  }
  .a{
    position: absolute;
    top: 22px;
    left: 10px;
  }
  .d{
    position: absolute;
    width: 150px;
    top: 15px;
    left: 50px;
  }
  .d input{
    height: 20px;
  }
  .g{
    position: absolute;
    left: 400px;
  }
  .b{
    position: absolute;
    left: 250px;
    top: 22px;
  }
  .f{
    position: absolute;
    top: 15px;
    left: 270px;
  }
  .c {
    position: absolute;
    top: 22px;
    left: 450px;
  }
  .e{
    position: absolute;
    width: 150px;
    top: 15px;
    left: 490px;
  }
  .g{
    position: absolute;
    left: 760px;
    top: 15px;
  }
  .h{
    position: absolute;
    left: 680px;
    top:15px;
  }
  .L{
    position: absolute;
    left: 900px;
    top: 15px;
  }
  .classone{
    position: relative;
    left: 40%;
    margin-top: 10px;
  }
  .select-btn-box{
    position: absolute;
    left: 660px;
    top: 15px;
  }
</style>
