import request from '@/utils/request'

/**
 * 学员模糊查询已经发布的培训班
 */
 export function getAllClassByMoHu (className, trainNumber, registerOpenTime) {
  return request({
    url: `/class/getAllClassByMoHu?className=${className}&trainNumber=${trainNumber}&registerOpenTime=${registerOpenTime}`,
    method: 'get'
  })
}

/**
 *获取登录用户的信息
 * @param className
 * @param trainNumber
 * @param registerOpenTime
 */
export function getUserInfo (requests, response) {
  return request({
    url: `/dev-api/getUserInfo?requests=${requests}&response=${response}`,
    method: 'get'
  })
}
/**
 * 查看班级->查看学员-删除
 */
 export function DeleteStudent (stuId, classId) {
  return request({
    url: `/bh/student/cancelRegistration?&stuId=${stuId}&classId=${classId}`,
    method: 'DELETE'
    // data: query
  })
}
/**
 * 审核管理
 * @param id
 * @constructor
 */
export function getApply (query) {
  return request({
    url: '/bh/student/getAll',
    method: 'get',
     params: {
      ...query
    }
  })
}
/**
 * 审核删除
 */
 export function DeleteApprove (stuId, classId) {
  return request({
    url: `/bh/student/cancelApplyExamine?&stuId=${stuId}&classId=${classId}`,
    method: 'DELETE'
    // data: query
  })
}
/**
 * 审核---转班级
 */
 export function newClass (stuId, classId, newClassId) {
  return request({
    url: `/bh/student/updateRegistration?&stuId=${stuId}&classId=${classId}&newClassId=${newClassId}`,
    method: 'get'
  })
}
/**
 * 查看班级->查看学员---转班级
 */
 export function newClassTwo (stuId, classId, newClassId) {
  return request({
    url: `/bh/student/ShiftTransfer?&stuId=${stuId}&classId=${classId}&newClassId=${newClassId}`,
    method: 'get'
  })
}
/**
 * 审核通过
 * @param id
 * @param status
 * @param noAuth
 */
export function auth (stuId, classId, status) {
  return request({
    url: `/beihaieducation/apply-examine/updAEStatus?stuId=${stuId}&classId=${classId}&status=${status}`,
    method: 'get'
  })
}

/**
 * 审核不通过
 * @param id
 * @param status
 * @param noAuth
 */
export function noAuth (stuId, classId, status, noAuth) {
  return request({
    url: `/beihaieducation/apply-examine/updAEStatus?stuId=${stuId}&classId=${classId}&status=${status}&noAuth=${noAuth}`,
    method: 'get'
  })
}
/**
 * 获取全部报班信息和分组情况
 * @param id
 * @param status
 * @param noAuth
 */
export function findStuToGroupInfo (classId, groupId, stuName, pageNum, pageSize) {
  return request({
    url: `/bh/group/findStuToGroupInfo?&classId=${classId}&groupId=${groupId}&stuName=${stuName}&pageNum=${pageNum}&pageSize=${pageSize}`,
    method: 'get'
  })
}
/**
 * 增加分组
 * @param className
 */
export function groupCommit (classId, groupName, description, type) {
  return request({
    url: `/bh/group/create?classId=${classId}&groupName=${groupName}&description=${description}&type=${type}`,
    method: 'get'
  })
}
/**
 * 查询班级
 */
export function getAllClass (className) {
  return request({
    url: `/class/getAllClassByMoHu?className=${className}`,
    method: 'get'
  })
}
/**
 * 删除班级
 */
export function del (id) {
  return request({
    url: '/class/delTrainClassById?id=' + id,
    method: 'get'
  })
}
/**
 * 修改班级
 */
export function UpdatA (id) {
  return request({
    url: `/class/getClassAll?id=${id}`,
    method: 'get'
  })
}
/**
 * 分组管理
 * 发送班级
 */
export function classGet (classID) {
  return request({
    url: `/class/findStuToGroupInfo?classID=${classID}`,
    method: 'get'
  })
}
/**
 * 分组管理
 * 添加分组
 */
export function groupCommitf (createGroupData) {
  return request({
    url: '/bh/group/create',
    method: 'post',
    data: createGroupData
  })
}
/**
 * 查询考核管理
 * @param trainClass
 * @constructor
 */
export function attendSel (query) {
  return request({
    url: '/attendance/get',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 根据主键id查询字典表
 */
export function GetAllById (id) {
  return request({
    url: `/dev-api/getAllById?id=${id}`,
    method: 'get',
    params: id
  })
}
/**
 * 打印二维码
 */
export function QR (id) {
  return request({
    url: `/class/printCode?id=${id}`,
    responseType: 'blob',
    method: 'get'
  })
}

/**
 * 导入exce表格
 */
export function excelStudent (excelStudent) {
  return request({
    url: 'bh/student/excelStudent',
    responseType: 'blob',
    method: 'post',
    data: excelStudent
  })
}
/**
 *修改考勤记录
 */
export function detailAttendance (courseId, stuId) {
  return request({
    url: '/attendance/detailAttendance?courseId=' + courseId + '&stuId=' + stuId,
    method: 'get'
  })
}
/**
 *修改考勤
 */
export function updAttendance (courseId, stuId, stuName, attStatus, date) {
  return request({
    url: `/attendance/updAttendance?courseId=${courseId}&stuId=${stuId}&stuName=${stuName}&attStatus=${attStatus}&date=${date}`,
    method: 'get'
  })
}
/**
 *批量审批
 */
export function plAEStatus (applyExaminesList) {
  return request({
    url: '/beihaieducation/apply-examine/plAEStatus',
    method: 'post',
    data: applyExaminesList
  })
}
/**
 *批量鉴定
 */
export function insBworBzr (authenticates) {
  return request({
    url: '/beihaieducation/authenticate/plinsBworBzr',
    method: 'post',
    data: authenticates
  })
}
/**
 *批量审批请假 --班主任审批
 */
 export function leaveBatch (query) {
  return request({
    url: '/leave/upLeaveHeads',
    method: 'post',
    data: query
  })
}
/**
 *批量审批请假 --管理员审批
 */
 export function leaveBatchAdmin (query) {
  return request({
    url: '/leave/upLeaveZzbs',
    method: 'post',
    data: query
  })
}
/**
 *批量审批请假 --班主任--是否需要组织部审批
 */
 export function isZZBAudit (query) {
  return request({
    url: '/leave/isZZBAudit/' + query,
    method: 'get'
  })
}
/**
 *批量分组
 */
export function batchAssign (groups, gDId) {
  return request({
    url: '/bh/group/batchAssign',
    method: 'post',
    data: {
      groups: groups,
      id: gDId
    }
  })
}
/**
 *随机分组
 */
 export function randomAssortment (groupIds) {
  return request({
    url: '/bh/group/randomAssortment',
    method: 'post',
    data: groupIds
  })
}
/**
 *全部随机分组
 */
 export function randomAssortmentAll (classId) {
  return request({
    url: '/bh/group/randomAssortmentAll',
    method: 'get',
    params: { classId }
  })
}
